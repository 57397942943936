import { Component, AfterViewInit } from '@angular/core';
import '../vendor/jitsi/external_api.js';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ICMD';
  domain = 'meet.jit.si';
  options: any;
  api: any;
  showVideo: boolean;

  startCalling() {
    this.showVideo = true;
  }

  ngAfterViewInit(): void {
    this.options = {
      roomName: 'ahmedfirsttry',
      width: 1000,
      height: 600,
      parentNode: document.querySelector('#meet')
    };

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);
    console.log(this.api);
  }



}
